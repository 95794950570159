/* Piperita - Theme */
/* by Jacob Tomlinson () */

/* Colours */
$base-white:      #E6E6E6;
$base-red:        #FF0028;
$light-black:     #737373;
$dark-black:      #4C4C4C;
$light-red:       #FFA8BA;
$dark-red:        #FF6685;
$light-green:     #C5EBC5;
$dark-green:      #A6EBA6;
$light-yellow:    #F9F9A5;
$dark-yellow:     #FFDC72;
$light-blue:      #8DDBFF;
$dark-blue:       #5DC6F5;
$light-purple:    #FFABFF;
$dark-purple:     #FF8FFF;
$light-aqua:      #B0F0F0;
$dark-aqua:       #86D1D7;
$light-white:     #FFFFFF;
$dark-white:      #DBDBDB;


/* Piperita Dark Title */
.hljs-title {
  color: $dark-white;
}

/* Piperita Dark Green */
.hljs-variable,
.hljs-attribute,
.hljs-tag,
.hljs-regexp,
.ruby .hljs-constant,
.xml .hljs-tag .hljs-title,
.xml .hljs-pi,
.xml .hljs-doctype,
.html .hljs-doctype,
.css .hljs-id,
.css .hljs-class,
.css .hljs-pseudo {
  color: $dark-green;
}

/* Piperita Dark Orange */
.hljs-number,
.hljs-preprocessor,
.hljs-pragma,
.hljs-built_in,
.hljs-literal,
.hljs-params,
.hljs-constant {
  color: #df5320;
}

/* Piperita Dark Yellow */
.hljs-ruby .hljs-class .hljs-title,
.css .hljs-rules .hljs-attribute {
  color: $dark-yellow;
}

/* Piperita Dark Red */
.hljs-string,
.hljs-value,
.hljs-inheritance,
.hljs-header,
.ruby .hljs-symbol,
.xml .hljs-cdata {
  color: $dark-red;
}

/* Piperita Dark Aqua */
.css .hljs-hexcolor {
  color: $dark-aqua;
}

/* Piperita Dark Blue */
.hljs-function,
.hljs-comment,
.python .hljs-decorator,
.python .hljs-title,
.ruby .hljs-function .hljs-title,
.ruby .hljs-title .hljs-keyword,
.perl .hljs-sub,
.javascript .hljs-title,
.coffeescript .hljs-title {
  color: $dark-blue;
}

/* Piperita Dark Purple */
.hljs-keyword,
.javascript .hljs-function {
  color: $dark-purple;
}

.hljs {
  display: block;
  overflow-x: auto;
  color: $base-white;
  -webkit-text-size-adjust: none;
}

.coffeescript .javascript,
.javascript .xml,
.tex .hljs-formula,
.xml .javascript,
.xml .vbscript,
.xml .css,
.xml .hljs-cdata {
  opacity: 0.5;
}
